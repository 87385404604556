import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/ContactBox.styles';
import { breakpoints } from '../style-utilities/variables';

const Div = styled.div`
  padding: 30px;
  background: black;
  color: white;
  margin: 0 0px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 0px 0 40px;
  }
  #icon {
    margin-bottom: 16px;
  }
  ${ComponentStyles}
`;

const ContactBox = ({ children, ...remainProps }) => {
  return <Div {...remainProps}>{children}</Div>;
};

export default ContactBox;
