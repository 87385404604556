import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/PagesHero.styles';
import Heading from '../components/Heading';
import { breakpoints } from '../style-utilities/variables';

const StyledHero = styled.div`
  min-height: 400px;
  background: ${props => `url(${props.image}) no-repeat center`};
  background-size: cover;
  .opacityOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    filter: alpha(opacity=60);
    opacity: 0.6;
    z-index: 1;
  }
  .disabled {
    display: none;
  }
  .opacity {
    position: relative;
    min-height: 400px;
    height: 300px; // This makes justify-content work in IE11
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  @media (min-width: ${breakpoints.tablet}) {
    /* background-attachment: fixed; */
    min-height: 550px;
    .opacity {
      min-height: 550px;
    }
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const PagesHero = ({
  children,
  enableOverlay,
  contactCenter,
  ...remainProps
}) => {
  return (
    <StyledHero {...remainProps}>
      <div className={enableOverlay === true ? 'opacityOverlay' : 'disabled'} />
      <div
        className={
          contactCenter === true ? 'opacity contact-center ' : 'opacity'
        }
      >
        {children}
      </div>
    </StyledHero>
  );
};

export default PagesHero;
